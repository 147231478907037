@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";

:global {
  @import "react-image-gallery/styles/scss/image-gallery.scss";
}

$image-ratio: calc(9 / 16);

.root {
  overflow: hidden;
  z-index: 0;

  :global {
    .image-gallery-thumbnail {
      &.active {
        border: 4px solid $color-button-primary-outline-border-hovered;
      }
    }

    .image-gallery-fullscreen-button {
      top: 0;
      bottom: auto;
      padding: 0.8rem 0.8rem 2.4rem 2.4rem;
      margin: 0;
      outline: none;

      &:hover {
        color: $color-button-primary-outline-border-hovered;
        outline: none;
      }

      .image-gallery-svg {
        width: 2.4rem;
        height: 2.4rem;

        @include breakpoint-medium-up {
          width: 3.6rem;
          height: 3.6rem;
        }
      }
    }

    .image-gallery-thumbnail-inner {
      padding-bottom: $image-ratio * 100%;
      background-color: #fff;
    }

    .image-gallery-description {
      line-height: 1.1;
      font-size: 1.2rem;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      font-weight: 500;

      @include breakpoint-small-up {
        font-size: 1.4rem;
        line-height: 1.2;
      }
    }

    .fullscreen {
      height: 100%;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      top: 0;
      transform: none;

      .image-gallery-slide-wrapper,
      .image-gallery-swipe,
      .image-gallery-slides,
      .image-gallery-slide {
        height: 100%;
      }

      .image-gallery-slide > div {
        height: 100%;
        padding-bottom: 0;
      }

      .image-gallery-slide.center {
        position: absolute;
      }

      img {
        object-fit: contain !important;
      }

      .image-gallery-slide-wrapper {
        position: relative;
        height: 100%;
        max-height: calc(100% - 75px);
      }

      .image-gallery-description {
        font-size: 1.4rem;
        line-height: 1.2;
      }
    }
  }
}

.imageGalleryImage {
  border-radius: 1rem;
  overflow: hidden;

  & > div {
    position: relative;
    width: 100%;
    padding-bottom: 40.37%;
  }

  img {
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.imageGalleryThumbnail {
  width: 12rem;
  border-radius: 1rem;
  overflow: hidden;

  &:hover {
    border: 4px solid $color-button-secondary-background-hovered;
  }

  &:focus {
    border-color: transparent;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    left: 0;
  }
}

.button {
  margin: 0.4rem;
  position: absolute;
  top: calc(50% - 2.2rem / 2);
  z-index: 1;
  width: 2.2rem;
  height: 2.2rem;
  background-color: white;
  border: 0;
  fill: $color-primary;
  border-radius: 0.5rem;
  opacity: 0.8;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint-small-up {
    top: calc(50% - 4.4rem / 2);
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 1rem;
  }
}

.buttonLeft {
  left: 0;
  transform: rotate(180deg);
}

.buttonRight {
  right: 0;
}

.chevron {
  height: 1rem;
  width: 0.6rem;

  @include breakpoint-small-up {
    height: 2rem;
    width: 1.2rem;
  }
}
